import { useEffect } from 'react';
import Swiper from 'swiper/bundle'; // Import Swiper bundle with all modules
import 'swiper/swiper-bundle.css'; // Import Swiper styles

const Testimonials = () => {
    useEffect(() => {
        const swiper = new Swiper('.slides-1', {
           slidesPerView: 1,
           spaceBetween: 30,
           loop: true, // Enable looping
           autoplay: {
              delay: 5000, // Autoplay delay in milliseconds
              disableOnInteraction: false, // Autoplay continues after user interaction
           },
           pagination: {
              el: '.swiper-pagination',
              clickable: true,
           },
           navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
           },
        });
     }, []);
  

   return (
    <section  className="testimonials section-bg">
       <div className="container" data-aos="fade-up">
        <div className="section-header invisible">
            <h2>Reviews</h2>
        </div>
        <div className="slides-1 swiper" data-aos="fade-up" data-aos-delay="100">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <div className="testimonial-item">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="testimonial-content">
                                <div className="stars text-center mb-3">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                "Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!"
                                <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                                <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img src="/assets/img/testimonials/testimonials-1.jpg" className="img-fluid testimonial-img" alt="" />
                                </div>
                                <div className="rating-spec">
                                    <h3>Mr.Mathew</h3>
                                    <h4>Cloi.ai</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* <!-- End testimonial item --> */}
                <div className="swiper-slide">
                    <div className="testimonial-item">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="testimonial-content">
                                <div className="stars text-center mb-3">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                "Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!"
                                <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                                <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img src="/assets/img/testimonials/testimonials-1.jpg" className="img-fluid testimonial-img" alt="" />
                                </div>
                                <div className="rating-spec">
                                    <h3>Mr.Mathew</h3>
                                    <h4>Cloi.ai</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* <!-- End testimonial item --> */}
                <div className="swiper-slide">
                    <div className="testimonial-item">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="testimonial-content">
                                <div className="stars text-center mb-3">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                "Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!"
                                <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                                <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img src="/assets/img/testimonials/testimonials-1.jpg" className="img-fluid testimonial-img" alt="" />
                                </div>
                                <div className="rating-spec">
                                    <h3>Mr.Mathew</h3>
                                    <h4>Cloi.ai</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* <!-- End testimonial item --> */}
                <div className="swiper-slide">
                    <div className="testimonial-item">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="testimonial-content">
                                <div className="stars text-center mb-3">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                "Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!"
                                <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                                <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img src="/assets/img/testimonials/testimonials-1.jpg" className="img-fluid testimonial-img" alt="" />
                                </div>
                                <div className="rating-spec">
                                    <h3>Mr.Mathew</h3>
                                    <h4>Cloi.ai</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* <!-- End testimonial item --> */}
            </div>
            <div className="swiper-pagination"></div>
            {/* <!-- Navigation buttons --> */}
            <div className="swiper-button-prev custom-swiper-button-prev"> <i className="fa-solid fa-circle-arrow-left"></i></div>
            <div className="swiper-button-next fa-circle-arrow-left"><i className="fa-solid fa-circle-arrow-right"></i></div>
        </div>
        </div>
    </section>
 
   );
};

export default Testimonials;

// utils/aosInit.js
import AOS from 'aos';
import 'aos/dist/aos.css';

export function initializeAOS() {
  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: false,
    mirror: false
  });
}

import React from 'react';


const ViewDemo = () => {
  return (
    <>
    {/* Product */}
      <section className="demo-section pb-0">
        <section className='banner-section'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-up">
                      <div className="service-welcome-content">
                          <h3 className="service-page-head">E- Learning App</h3>
                          <p className="head-para-content">
                          Sherlock is an innovative e-learning platform offering comprehensive education and training worldwide. It features diverse courses, interactive content, and personalized learning experiences for all ages and educational levels, aiming to bridge educational gaps and promote accessible, inclusive learning.
                          </p>
                          <button type="button" className="btn btn-primary btn-action-1">Get a Demo</button>
                      </div>
                    </div>
                    <div className="col-lg-6 position-relative" data-aos="fade-down">
                        <div className="demo-banner-container">
                            <div className="text-center">
                              <img src="/assets/img/banner/view-demo.png" className="img-fluid img-responsive demo-banner-img" alt="View Demo Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
     {/* Banner Section Ends.. */}

       <section className='demo-wrapper'>
           <div className='container'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-10 text-center'>
                         <h2 className="heading">Product Screenshots Showcase</h2>
                         <p className='para'>Explore the visual highlights of our cutting-edge applications. These screenshots offer a glimpse into the user experience, innovative features, and sleek design that define our product. Discover how our solutions can transform your digital experience through intuitive and engaging interfaces.</p>
                    </div>
                </div>
                <div className="row">
                    <div className='col-lg-12'>
                        <div className='card transparent-card'>
                            <div className="demo-screen-container">
                                <img src="/assets/img/demo/showcase-1.svg" className="img-fluid img-responsive" alt="Screen 1" />
                            </div>
                        </div>   
                    </div>
                    {/* Col Ends... */}
                    <div className='col-lg-12'>
                        <div className='card transparent-card'>
                            <div className="demo-screen-container">
                                <img src="/assets/img/demo/showcase-2.svg" className="img-fluid img-responsive" alt="Screen 2" />
                            </div>
                        </div>   
                    </div>
                    {/* Col Ends... */}
                </div>
                {/* Row Ends... */}
                <div className="row d-flex justify-content-center mt-5">
                    {/* Thanking Message */}
                    <div className='col-lg-6'>
                         <div className='text-center thanking-info mt-5'>
                              <h3>Thank You for Viewing!</h3>
                              <p>We hope you enjoyed exploring our product screenshots. Contact us today to schedule a free demo and experience our innovative solutions firsthand.</p>
                         </div>
                    </div>
                </div>
           </div>
       </section>


       <section>
           <div className="footer-banner-container">
                <img src="/assets/img/demo/footer-banner.svg" className="img-fluid img-responsive w-100" alt="Footer Banner" />
           </div>
       </section>


   {/* <!-- ===  Contact Us Section ==== --> */}
   <section className="contactus-section" style={{ minHeight : '700px' }}>
       <div className="container">
           <div className="row">
              <div data-aos="zoom-in" className="col-lg-5 col-md-12 col-sm-12 col-12 contactus-info-col">
                <div className="contactus-info-holder">
                  <h3 className="contactus-heading">Contact Us Today</h3>
                  <p className="pe-0 pe-lg-5">Feel free to reach out today for assistance with any questions or issues. Our dedicated team is here to provide prompt and effective help, ensuring your needs are met.</p>
                </div>
              </div>
              <div data-aos="zoom-out" data-aos-duration="500" className="col-lg-7 form-column">
                  <div className="card form-card">
                     <div className="card-body">
                         <form action="#" method="POST">
                              {/* @csrf */}
                             <div className="row">
                                <div className="col-lg-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Enter your name" />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label htmlFor="pnumber" className="form-label">Phone Number</label>
                                    <input type="tel" className="form-control" id="pnumber" placeholder="Enter your phone no" />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" placeholder="Enter email address" />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label htmlFor="requirements" className="form-label">Requirements</label>
                                    <textarea className="form-control" id="requirements" placeholder="Write here" rows="3"></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                      <button type="button" className="btn btn-get-quote ms-0 mt-3">Get a Quote</button>
                                </div>
                             </div>
                         </form>
                     </div>
                  </div>
              </div>
           </div>
           {/* <!-- Row Ends --> */}
       </div>
       {/* <!-- Container Ends.. --> */}
    
   </section>
 {/* <!-- ===  Contact Us Section  Ends==== --> */}




   

      </section>
      {/* Product Demo Section Ends */}

     
    </>
  );
}

export default ViewDemo;

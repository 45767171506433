import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Products from "./pages/Products";
import ViewDemo from "./pages/ViewDemo";
import ContactUs from "./pages/ContactUs";
import { initializeAOS } from './utils/aosInit';


function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to show preloader
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust time as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Initialize AOS on component mount
    initializeAOS();
  }, []);

  if (isLoading) {
    return (
      <div id="preloader" className="preloader">
        {/* Your preloader content here */}
      </div>
    );
  }

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/view-demo" element={<ViewDemo />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

import React from "react";
import { Link } from 'react-router-dom';
import { useEffect } from "react";

const TrendingSlider = () => {
   useEffect(() => {
      // Create link element for Swiper CSS
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = `${process.env.PUBLIC_URL}/assets/vendor/swiper/trending-slider.min.css`;
      document.head.appendChild(link);
  
      // Create script element for Swiper JS
      const script = document.createElement("script");
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.5/swiper-bundle.min.js";
      script.async = true;
  
      script.onload = () => {
        // Initialize Swiper with the specified configuration
        new window.Swiper(".swiper", {
          effect: "coverflow",
          grabCursor: true,
          centeredSlides: true,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 4,
            slideShadows: true,
          },
          loop: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          keyboard: {
            enabled: true,
          },
          mousewheel: {
            thresholdDelta: 70,
          },
          breakpoints: {
            560: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });
      };
  
      document.body.appendChild(script);
  
      // Cleanup function to remove the CSS and JS when the component unmounts
      return () => {
        document.head.removeChild(link);
        document.body.removeChild(script);
      };
    }, []);
  
  return (
   <div class="demo-slider-wrapper">
      <div className="container">
         <section className="demo-swiper-section">
         <div className="swiper-container">
            <div className="swiper">
               <div className="swiper-wrapper">
                 <div className="swiper-slide swiper-slide--one">
                     <div className="slide-content">
                        <h2 className="demo-heading">E-Learning App</h2>
                        <ul>
                           <li>Book, Ride, Pay</li>
                           <li>Tailored solutions for diverse user needs</li>
                           <li>Effortlessly book rides with real-time tracking</li>
                        </ul>
                        <Link
                           to="/view-demo"
                           className="btn btn-primary view-demo-btn mt-5"
                        >
                           View Demo
                        </Link>
                     </div>
                 </div>
                  <div className="swiper-slide swiper-slide--two">
                     <div className="slide-content">
                        <h2 className="demo-heading">Travel and Tourism Apps</h2>
                        <ul>
                           <li>Book, Ride, Pay</li>
                           <li>Tailored solutions for diverse user needs</li>
                           <li>Effortlessly book rides with real-time tracking</li>
                        </ul>
                        <Link
                           to="/view-demo"
                           className="btn btn-primary view-demo-btn mt-5"
                        >
                           View Demo
                        </Link>
                      
                     </div>
                  </div>
                  <div className="swiper-slide swiper-slide--two">
                     <div className="slide-content">
                        <h2 className="demo-heading">E-Commerce App</h2>
                        <ul>
                           <li>Book, Ride, Pay</li>
                           <li>Tailored solutions for diverse user needs</li>
                           <li>Effortlessly book rides with real-time tracking</li>
                        </ul>
                        <Link
                           to="/view-demo"
                           className="btn btn-primary view-demo-btn mt-5"
                        >
                           View Demo
                        </Link>
                     </div>
                  </div>
               </div>
                {/* Swiper Ends.... */}

               {/* <!-- If we need navigation buttons --> */}
               <div className="swiper-button-prev">
                  <i style={{ fontSize: '25px !important;'}} class="fa-solid fa-chevron-left"></i>
               </div>
               <div className="swiper-button-next">
                  <i style={{ fontSize: '25px !important;'}} class="fa-solid fa-chevron-right"></i>
               </div>
            </div>
         </div>
      </section>
      </div>
    </div>
   
  );
};

export default TrendingSlider;

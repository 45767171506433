import React from 'react';
import TrendingSlider from '../components/TrendingSlider';


const Products = () => {
  return (
    <>
   
      
      <section className="product-section pb-0">
        <section className='banner-section'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-up">
                      <div className="service-welcome-content">
                          <h3 className="service-page-head">Explore <span style={{ color: '#F22735' }} className='px-1'>NovelX's</span>Leading Digital Innovations </h3>
                          <p className="head-para-content">
                             At NovelX Technology, we deliver top-tier digital solutions that set industry standards, from innovative apps to web products designed for diverse business needs. Discover how our cutting-edge technology drives digital transformation and elevates business outcomes.
                          </p>
                          <button type="button" className="btn btn-primary btn-action-1">Get a Demo</button>
                      </div>
                    </div>
                    <div className="col-lg-6 position-relative" data-aos="fade-down">
                        <div className="aboutus-banner-container">
                            <div className="text-center">
                              <img src="/assets/img/product/banner.svg" className="img-fluid img-responsive product-banner-img" alt="Service Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
           {/* Banner Section Ends.. */}

         <section className="content-section" data-aos="fade-up">
              <div className='container'>
                 <h2>Explore Our Innovative Product Suite for Enhanced Digital Solutions</h2>
                 <p>Explore our comprehensive portfolio of innovative digital solutions designed to enhance user experiences and drive business growth. From intuitive mobile apps to robust web platforms, our products are crafted to meet diverse needs across industries, ensuring scalability, efficiency, and seamless integration with modern technologies.</p>
              </div>
          </section>  

          {/* DEmo Swiper Card */}
          <TrendingSlider/>
          {/* DEmo Swiper Card */}

        <div className="container my-5" data-aos="fade-up">
          <div className="row">
              <div className='col-lg-4 mb-4'>
                  <div className='card product-card'>
                       <div className="text-start">
                          <img src="/assets/img/product/icons/check.svg" className="img-responsive" alt="Check Icon" />
                       </div>
                       <h5>Expert App Development</h5>
                       <p>Specializing in custom iOS, Android, and cross-platform app development.</p>
                  </div>
              </div>
              {/* Col Ends... */}
              <div className='col-lg-4 mb-4'>
                  <div className='card product-card'>
                       <div className="text-start">
                          <img src="/assets/img/product/icons/check.svg" className="img-responsive" alt="Check Icon" />
                       </div>
                       <h5>Innovative Solutions</h5>
                       <p>ailored apps that enhance user experience and drive business growth.</p>
                  </div>
              </div>
              {/* Col Ends... */}
              <div className='col-lg-4 mb-4'>
                  <div className='card product-card'>
                       <div className="text-start">
                          <img src="/assets/img/product/icons/check.svg" className="img-responsive" alt="Check Icon" />
                       </div>
                       <h5>Proven Results</h5>
                       <p>Delivering successful projects across diverse industries and client satisfaction.</p>
                  </div>
              </div>
              {/* Col Ends... */}
              <div className='col-lg-4 mb-4'>
                  <div className='card product-card'>
                       <div className="text-start">
                          <img src="/assets/img/product/icons/check.svg" className="img-responsive" alt="Check Icon" />
                       </div>
                       <h5>Cutting-Edge Technology</h5>
                       <p>Utilizing the latest tools and frameworks for scalable and secure applications.</p>
                  </div>
              </div>
              {/* Col Ends... */}
              <div className='col-lg-4 mb-4'>
                  <div className='card product-card'>
                       <div className="text-start">
                          <img src="/assets/img/product/icons/check.svg" className="img-responsive" alt="Check Icon" />
                       </div>
                       <h5>Client-Centric Approach</h5>
                       <p>Focused on understanding client needs and delivering impactful solutions.</p>
                  </div>
              </div>
              {/* Col Ends... */}
          </div>
        </div>
        {/* Card Section Ends... */}
         
        
         {/* <!-- ===  Contact Us Section ==== --> */}
   <section className="contactus-section" style={{ minHeight : '700px' }}>
       <div className="container">
           <div className="row">
              <div data-aos="zoom-in" className="col-lg-5 col-md-12 col-sm-12 col-12 contactus-info-col">
                <div className="contactus-info-holder">
                  <h3 className="contactus-heading">Contact Us Today</h3>
                  <p className="pe-0 pe-lg-5">Feel free to reach out today for assistance with any questions or issues. Our dedicated team is here to provide prompt and effective help, ensuring your needs are met.</p>
                </div>
              </div>
              <div data-aos="zoom-out" data-aos-duration="500" className="col-lg-7 form-column">
                  <div className="card form-card">
                     <div className="card-body">
                         <form action="#" method="POST">
                              {/* @csrf */}
                             <div className="row">
                                <div className="col-lg-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Enter your name" />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label htmlFor="pnumber" className="form-label">Phone Number</label>
                                    <input type="tel" className="form-control" id="pnumber" placeholder="Enter your phone no" />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" placeholder="Enter email address" />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label htmlFor="requirements" className="form-label">Requirements</label>
                                    <textarea className="form-control" id="requirements" placeholder="Write here" rows="3"></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                      <button type="button" className="btn btn-get-quote ms-0 mt-3">Get a Quote</button>
                                </div>
                             </div>
                         </form>
                     </div>
                  </div>
              </div>
           </div>
           {/* <!-- Row Ends --> */}
       </div>
       {/* <!-- Container Ends.. --> */}
    
   </section>
 {/* <!-- ===  Contact Us Section  Ends==== --> */}


      </section>
      {/* About Us Section Ends */}

     
    </>
  );
}

export default Products;

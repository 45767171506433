import React, { useEffect, useRef, useState } from 'react';
import { FaQuoteLeft } from 'react-icons/fa';

import { loadBootstrapScript } from '../utils/loadBootstrap';

const TestimonialCarousel = () => {
    const carouselRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [cardWidth, setCardWidth] = useState(0); 
    const [carouselWidth, setCarouselWidth] = useState(0);

    useEffect(() => {
        const loadAndInitializeCarousel = async () => {
            try {
                await loadBootstrapScript(); // Load Bootstrap script
                const carouselElement = carouselRef.current;

                if (window.bootstrap && window.bootstrap.Carousel) {
                    if (window.matchMedia("(min-width:576px)").matches) {
                        new window.bootstrap.Carousel(carouselElement, {
                            interval: 1000,
                            pause: false,
                        });

                        setCardWidth(carouselElement.querySelector(".carousel-item").clientWidth);
                        setCarouselWidth(carouselElement.querySelector(".carousel-inner").scrollWidth);
                    } else {
                        carouselElement.classList.add("slide");
                    }
                } else {
                    console.error("Bootstrap's JavaScript is not loaded.");
                }
            } catch (error) {
                console.error(error);
            }
        };

        loadAndInitializeCarousel();
    }, []);

    const handleNext = () => {
        if (scrollPosition < carouselWidth - cardWidth * 3) {
            setScrollPosition(prevPosition => prevPosition + cardWidth);
        }
    };

    const handlePrev = () => {
        if (scrollPosition > 0) {
            setScrollPosition(prevPosition => prevPosition - cardWidth);
        }
    };

    useEffect(() => {
        const carouselInner = carouselRef.current.querySelector(".carousel-inner");
        carouselInner.scrollTo({ left: scrollPosition, behavior: "smooth" });
    }, [scrollPosition]);

    return (
        <div id="testimonialCarousel" className="carousel" ref={carouselRef}>
            {/* Left and Right Slide Control Buttons */}
            <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '60px'}}>
                <div>
                   <h2 className="TES-PH">See what our customer<br></br>
                   say about us</h2>
                </div>
                <div className="button-controls d-flex align-items-center me-lg-5 me-md-5 me-3">
                    <button className="carousel-control-prev me-3" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="prev" onClick={handlePrev}>
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="next" onClick={handleNext}>
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            {/* Carousel Inner */}
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div className="card shadow-sm rounded-3">
                        <div className="quotes display-6 text-body-tertiary">
                            <FaQuoteLeft />
                        </div>
                        <div className="card-body">
                            <p className="card-text">Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!</p>
                            <div className="d-flex align-items-center pt-2">
                                <img src="https://codingyaar.com/wp-content/uploads/square-headshot-1.png" alt="bootstrap testimonial carousel slider 2" />
                                <div>
                                    <h5 className="card-title fw-bold">Jane Doe</h5>
                                    <span className="text-secondary">Cloi.ai</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="card shadow-sm rounded-3">
                        <div className="quotes display-6 text-body-tertiary">
                            <FaQuoteLeft />
                        </div>
                        <div className="card-body">
                            <p className="card-text">Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!</p>
                            <div className="d-flex align-items-center pt-2">
                                <img src="https://codingyaar.com/wp-content/uploads/square-headshot-2.png" alt="bootstrap testimonial carousel slider 2" />
                                <div>
                                    <h5 className="card-title fw-bold">June Doe</h5>
                                    <span className="text-secondary">Cloi.ai</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="card shadow-sm rounded-3">
                        <div className="quotes display-6 text-body-tertiary">
                            <FaQuoteLeft />
                        </div>
                        <div className="card-body">
                            <p className="card-text">Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!</p>
                            <div className="d-flex align-items-center pt-2">
                                <img src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg" alt="bootstrap testimonial carousel slider 2" />
                                <div>
                                    <h5 className="card-title fw-bold">John Doe</h5>
                                    <span className="text-secondary">Cloi.ai</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="card shadow-sm rounded-3">
                        <div className="quotes display-6 text-body-tertiary">
                            <FaQuoteLeft />
                        </div>
                        <div className="card-body">
                            <p className="card-text">Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!</p>
                            <div className="d-flex align-items-center pt-2">
                                <img src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg" alt="bootstrap testimonial carousel slider 2" />
                                <div>
                                    <h5 className="card-title fw-bold">John Doe</h5>
                                    <span className="text-secondary">CEO, Artio</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="card shadow-sm rounded-3">
                        <div className="quotes display-6 text-body-tertiary">
                            <FaQuoteLeft />
                        </div>
                        <div className="card-body">
                            <p className="card-text">Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!</p>
                            <div className="d-flex align-items-center pt-2">
                                <img src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg" alt="bootstrap testimonial carousel slider 2" />
                                <div>
                                    <h5 className="card-title fw-bold">John Doe</h5>
                                    <span className="text-secondary">CEO, Capitalfy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="card shadow-sm rounded-3">
                        <div className="quotes display-6 text-body-tertiary">
                            <FaQuoteLeft />
                        </div>
                        <div className="card-body">
                            <p className="card-text">Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!</p>
                            <div className="d-flex align-items-center pt-2">
                                <img src="https://codingyaar.com/wp-content/uploads/square-headshot-2.png" alt="bootstrap testimonial carousel slider 2" />
                                <div>
                                    <h5 className="card-title fw-bold">June Doe</h5>
                                    <span className="text-secondary">CEO, InfinQx</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="card shadow-sm rounded-3">
                        <div className="quotes display-6 text-body-tertiary">
                            <FaQuoteLeft />
                        </div>
                        <div className="card-body">
                            <p className="card-text">Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!</p>
                            <div className="d-flex align-items-center pt-2">
                                <img src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg" alt="bootstrap testimonial carousel slider 2" />
                                <div>
                                    <h5 className="card-title fw-bold">John Doe</h5>
                                    <span className="text-secondary">CEO, BlissNova</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="card shadow-sm rounded-3">
                        <div className="quotes display-6 text-body-tertiary">
                            <FaQuoteLeft />
                        </div>
                        <div className="card-body">
                            <p className="card-text">Working with Novelx Technologies was an amazing experience. They helped bring our app idea to life seamlessly and delivered beyond our expectations. Highly recommend!</p>
                            <div className="d-flex align-items-center pt-2">
                                <img src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg" alt="bootstrap testimonial carousel slider 2" />
                                <div>
                                    <h5 className="card-title fw-bold">John Doe</h5>
                                    <span className="text-secondary">CEO, Square Markets</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Repeat for other carousel items if needed */}
            </div>
        </div>
    );
};

export default TestimonialCarousel;
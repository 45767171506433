import React from 'react';
import TestimonialCarousel from '../components/TestimonialCarousel';


const Services = () => {
  return (
    <>
   
      
      <section className="service-section">
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-up">
                      <div className="service-welcome-content">
                          <h3 className="service-page-head">Explore our leading digital transformation services</h3>
                          <p className="head-para-content">
                          Leading the way in digital transformation, explore our comprehensive range of pioneering services and solutions tailored to elevate your business.
                          </p>
                          <button type="button" className="btn btn-primary btn-action-1">Contact Us</button>
                      </div>
                    </div>
                    <div className="col-lg-6" data-aos="fade-down">
                        <div className="aboutus-banner-container">
                            <div className="text-center">
                            <img src="/assets/img/services/banner.svg" className="img-fluid img-responsive service-banner-img" alt="Service Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
           {/* Banner Section Ends.. */}

         <section className='service-we-offer' data-aos="fade-up">
            <div className='container'>
                <div className="row">
                    <div className='col-lg-8'>
                       <h2 className="top-heading">Take a look at the variety of services we offer.</h2>
                    </div>   
                </div>
                <p className="para-p">Explore our diverse range of services at NovelX Technology, including app development, UI/UX design, software integration, and digital transformation solutions. Whether you need innovative mobile apps or seamless software integration, we deliver excellence tailored to meet your business needs and goals.</p>
                {/* Service Card Rows starts */}
                <div className='row mt-5'>
                    <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0'>
                        <div className='card service-card'>
                            <div className='card-body p-0'>
                                <div className='service-avatar'>
                                    <img src="/assets/img/services/icons/uiux.svg" className="img-fluid img-responsive" alt="UI UX" />
                                </div>
                                <h5 className="service-title">UIUX Design</h5>
                                <p className="service-slogan">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
                            </div>
                        </div>
                    </div>
                    {/* Col Ends.. */}
                    <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0'>
                        <div className='card service-card'>
                            <div className='card-body p-0'>
                                <div className='service-avatar'>
                                    <img src="/assets/img/services/icons/app.svg" className="img-fluid img-responsive" alt="App Dev" />
                                </div>
                                <h5 className="service-title">App development</h5>
                                <p className="service-slogan">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
                            </div>
                        </div>
                    </div>
                    {/* Col Ends.. */}
                    <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0'>
                        <div className='card service-card'>
                            <div className='card-body p-0'>
                                <div className='service-avatar'>
                                    <img src="/assets/img/services/icons/marketing.svg" className="img-fluid img-responsive" alt="Marketing" />
                                </div>
                                <h5 className="service-title">Marketing</h5>
                                <p className="service-slogan">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
                            </div>
                        </div>
                    </div>
                    {/* Col Ends.. */}
                    <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0'>
                        <div className='card service-card'>
                            <div className='card-body p-0'>
                                <div className='service-avatar'>
                                    <img src="/assets/img/services/icons/branding.svg" className="img-fluid img-responsive" alt="Branding" />
                                </div>
                                <h5 className="service-title">Branding</h5>
                                <p className="service-slogan">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
                            </div>
                        </div>
                    </div>
                    {/* Col Ends.. */}
                </div>
            </div> 
         </section>

         <section className='our-specialization' data-aos="fade-up">
            <div className="container">
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-8'>
                       <h5 className='section-sm-heading'>What we Do?</h5>
                       <h2 className='section-lg-heading'>Our Specialization</h2>
                       <div className='row mt-5'>
                           <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-5'>
                               <div className='card specialization-card'>
                                   <div className="d-flex align-items-center">
                                      <div className="me-3">
                                        <img src="/assets/img/services/icons/special/uiux.svg" className="img-fluid img-responsive" alt="UI/UX Designing" />
                                      </div>
                                      <h4 className="OS-Title">UI/UX Designing</h4>
                                    </div>
                                    <p className="OS-para">Really boy law county she unable her sister. Feet you off its like like six. Among sex are leave law built now. In built table in an rapid blush. Merits behind on afraid or warmly.</p>
                                    <div className="read-more">
                                        <a href="/" className="read-more-action">Read More</a>
                                    </div>
                               </div>
                           </div>
                           {/* Col Ends... */}
                           <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-5'>
                               <div className='card specialization-card'>
                                   <div className="d-flex align-items-center">
                                      <div className="me-3">
                                        <img src="/assets/img/services/icons/special/web-development.svg" className="img-fluid img-responsive" alt="Web Development" />
                                      </div>
                                      <h4 className="OS-Title">Web Development</h4>
                                    </div>
                                    <p className="OS-para">Really boy law county she unable her sister. Feet you off its like like six. Among sex are leave law built now. In built table in an rapid blush. Merits behind on afraid or warmly.</p>
                                    <div className="read-more">
                                        <a href="/" className="read-more-action">Read More</a>
                                    </div>
                               </div>
                           </div>
                           {/* Col Ends... */}
                           <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-5'>
                               <div className='card specialization-card'>
                                   <div className="d-flex align-items-center">
                                      <div className="me-3">
                                        <img src="/assets/img/services/icons/special/app-development.svg" className="img-fluid img-responsive" alt="Web Development" />
                                      </div>
                                      <h4 className="OS-Title">App Development</h4>
                                    </div>
                                    <p className="OS-para">Really boy law county she unable her sister. Feet you off its like like six. Among sex are leave law built now. In built table in an rapid blush. Merits behind on afraid or warmly.</p>
                                    <div className="read-more">
                                        <a href="/" className="read-more-action">Read More</a>
                                    </div>
                               </div>
                           </div>
                           {/* Col Ends... */}
                           <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-5'>
                               <div className='card specialization-card'>
                                   <div className="d-flex align-items-center">
                                      <div className="me-3">
                                        <img src="/assets/img/services/icons/special/web-development.svg" className="img-fluid img-responsive" alt="Web Development" />
                                      </div>
                                      <h4 className="OS-Title">Php Development</h4>
                                    </div>
                                    <p className="OS-para">Really boy law county she unable her sister. Feet you off its like like six. Among sex are leave law built now. In built table in an rapid blush. Merits behind on afraid or warmly.</p>
                                    <div className="read-more">
                                        <a href="/" className="read-more-action">Read More</a>
                                    </div>
                               </div>
                           </div>
                           {/* Col Ends... */}
                       </div>
                       {/* Row Ends... */}
                       <div className="d-flex justify-content-center">
                           <button type="button" className="btn btn-primary btn-action-1 px-5">Learn More</button>
                       </div>
                    </div>
                </div>
            </div>
         </section>
         
         {/* Testimonial Carousel */}
         <section className='testimonial-carousel' data-aos="fade-up">
            <div className="container">
                 <TestimonialCarousel/>
            </div>     
         </section>
          {/* Testimonial Carousel */}

         {/* Service Footer Banner */}
          <section className="service-footer-banner" data-aos="fade-up">
              <div className="container">
                  <div className="row d-flex justify-content-center">
                      <div className='col-lg-11'>
                           <div className="card Service-Footer-Card">
                               <div className='d-flex justify-content-between SFC-DIV'>
                                   <div className='join-with-us'>
                                      <h5 className='join-us-text'>Join with us</h5>
                                      <p>Come do meaningful work with kind, smart colleagues who’re invested in your growth.</p>
                                      <button type="button" className="btn btn-primary btn-action-1">Apply a position</button>
                                   </div>
                                   <div className=''>
                                      <div className="service-footer-banner" >
                                         <img src="/assets/img/services/footer-banner.svg" className=" img-responsive footer-banner-img" alt="Service Footer Banner" />
                                      </div>
                                   </div>
                               </div>
                           </div>
                      </div>
                  </div>
              </div>
          </section>
          {/* Service Footer Banner */}


      </section>
      {/* About Us Section Ends */}

     
    </>
  );
}

export default Services;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const location = useLocation();  // To get the current route

    useEffect(() => {
        // Toggle the mobile menu when clicking on the toggle icons or the custom close button
        const mobileNavToggler = () => {
            setMobileNavActive(prevState => !prevState);
        };

        // Close the mobile menu when clicking the custom close button
        const closeMenu = () => {
            setMobileNavActive(false);  // Always hide the menu when close is clicked
        };

        // Add event listeners for the toggle icons and the custom close button
        document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
            el.addEventListener('click', mobileNavToggler);
        });

        const closeButton = document.querySelector('.close.nw_red');
        if (closeButton) {
            closeButton.addEventListener('click', closeMenu);
        }

        // Clean up event listeners when component unmounts
        return () => {
            document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
                el.removeEventListener('click', mobileNavToggler);
            });

            if (closeButton) {
                closeButton.removeEventListener('click', closeMenu);
            }
        };
    }, []);

    // Function to check if a link is active based on the current route
    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <header id="header" className={`header fixed-top d-flex align-items-center ${mobileNavActive ? 'mobile-nav-active' : ''}`}>
            <div className="container d-flex align-items-center justify-content-between">
                <Link to="/" className="logo d-flex align-items-center me-auto me-lg-0">
                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="Logo" />
                </Link>

                <nav id="navbar" className="navbar">
                    <ul>
                       <div className="d-flex align-items-center justify-content-between">
                          <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} style={{ height: '55px', position: 'relative', top: '-5px' }} className="mobile-Logo" alt="Mobile Menu Logo" />
                          <div className="close nw_red"></div>
                       </div>
                        <li><Link to="/home" className={isActive('/home')}>Home</Link></li>
                        <li><Link to="/about-us" className={isActive('/about-us')}>About Us</Link></li>
                        <li><Link to="/services" className={isActive('/services')}>Services</Link></li>
                        <li><Link to="/products" className={isActive('/products')}>Products</Link></li>
                        <li><Link to="/contact" className={isActive('/contact')}>Contact Us</Link></li>
                    </ul>
                </nav>

                <Link type="button" className="btn btn-primary contactus-btn" to="/contact">Contact Us</Link>
                {/* Hamburger (list) icon */}
                <i className={`mobile-nav-toggle mobile-nav-show bi bi-list ${mobileNavActive ? 'd-none' : ''}`}></i>
                {/* Close (X) icon */}
                <i className={`mobile-nav-toggle mobile-nav-hide d-none bi bi-x ${mobileNavActive ? '' : 'd-none'}`}></i>
            </div>
        </header>
    );
}

export default Header;

import React from 'react';


const AboutUs = () => {
  return (
    <>
   
      
      <section id="about-us" className="aboutus-section pb-0">
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-up">
                      <div className="about-welcome-content">
                          <h3 className="aboutus-page-head">About Us</h3>
                          <h6 className="subheading">About NovelX Technology: Crafting Innovation in App Development</h6>
                          <p className="head-para-content">
                          Welcome to NovelX Technology, where innovation meets excellence in app development. At NovelX, we specialize in creating cutting-edge solutions that redefine user experiences and drive digital transformation across industries.
                          </p>
                          <button type="button" className="btn btn-primary btn-action-1">Contact Us</button>
                      </div>
                    </div>
                    <div className="col-lg-6" data-aos="fade-down">
                        <div className="aboutus-banner-container">
                            <div className="text-center">
                            <img src="/assets/img/banner/aboutus-banner.svg" className="img-fluid img-responsive aboutus-banner-img" alt="About Us Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
           {/* Banner Section Ends.. */}

          {/* What we Offer section starts */}
        <section className="get-to-know-us-section">
            <div className="container">
                <div className='row'>
                   <div className='col-lg-6'>
                      <div className='card card-ABT one p-0 m-0' data-aos="flip-left">
                              <div className="top-abt-card-img">
                                <img src="/assets/img/aboutus/1.png" className="img-fluid img-responsive img1" alt="About us banner 1" />
                              </div>
                        </div>
                   </div>
                   <div className='col-lg-4'>
                       <div className="content-box one" data-aos="fade-right">
                           <h5 className="text-white">What We Offer</h5>
                           <p>Whether you're a startup looking to launch your first app or an established enterprise seeking to streamline operations, NovelX Technology offers comprehensive app development services that cover the entire lifecycle of your project.</p>
                       </div>
                   </div>
                </div>
                {/* Row Ends.... */}
                <div className='row'>
                   <div className='col-lg-6 d-flex justify-content-end align-items-center align-self-center wt-we-of-col'>
                       <div className="content-box two" data-aos="fade-left">
                          <h5 className="text-white">Our Mission</h5>
                          <p>At NovelX, our mission is clear: to empower businesses through innovative technology solutions that enhance efficiency, foster growth, and exceed expectations. We aim to lead the app development industry by embracing emerging technologies and leveraging our expertise to deliver impactful solutions.</p>
                       </div>
                   </div>
                   <div className='col-lg-4' data-aos="flip-right">
                      <div className='card card-ABT two p-0 m-0'>
                              <div className="bottom-abt-card-img">
                                <img src="/assets/img/aboutus/2.png" className="img-responsive" alt="About us banner 1" />
                              </div>
                        </div>
                   </div>
                </div>
                {/* Row Ends.... */}
            </div>
        </section>
        {/* What we Offer section Ends */}
          
          {/* Get To Know Us */}
        <section className='Get-To-Know-Us'>
           <div className='container'>
              <div className="row d-flex justify-content-center" data-aos="zoom-out-up">
                 <div className='col-lg-10'>
                      <h4 className='text-center section-heading'>Get to know us</h4>
                      <p className='para-p'>Deupload is a 100% remote team. We believe that creative collaboration can happen anywhere and want our team to work where they feel most comfortable and inspired.</p>
                 </div>
              </div>
              {/* Row Ends... */}
              <div className='row gx-3 d-flex justify-content-center mt-5' data-aos="zoom-in-up">
                  <div className="col-lg-2 col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/1.png" className="img-responsive avatar-img" alt="Profile 1" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/2.png" className="img-responsive avatar-img" alt="Profile 2" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/3.png" className="img-responsive avatar-img" alt="Profile 3" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/4.png" className="img-responsive avatar-img" alt="Profile 4" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/5.png" className="img-responsive avatar-img" alt="Profile 5" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/6.png" className="img-responsive avatar-img" alt="Profile 6" />
                    </div>
                  </div>
              </div>
              {/* Row Ends.... */}
              <div className='row gx-3 d-flex justify-content-center mt-4' data-aos="zoom-in-up">
                  <div className="col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/7.png" className="img-responsive avatar-img" alt="Profile 7" />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/8.png" className="img-responsive avatar-img" alt="Profile 8" />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/9.png" className="img-responsive avatar-img" alt="Profile 9" />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/10.png" className="img-responsive avatar-img" alt="Profile 10" />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className='avatar-container'>
                        <img src="/assets/img/aboutus/avatar/11.png" className="img-responsive avatar-img" alt="Profile 11" />
                    </div>
                  </div>
              </div>
              {/* Row Ends.... */}
           </div>
        </section>
        {/* Get to know about Us */}
          
          {/* Our Team */}
        <section className='Get-To-Know-Our-Team' >
           <div className='container' data-aos="fade-right">
              <h4 className='section-heading'>Get to Know Our Team</h4>
              <p className='para-p'>Bringing together diverse talents and expertise, our team is dedicated to achieving excellence and driving innovation. With a shared passion for success, we leverage our unique skills to tackle challenges and seize opportunities. Together, we foster a culture of creativity and continuous improvement. Our commitment to quality drives us to deliver exceptional results and exceed expectations.</p>
           </div>
           <div className="row mt-5">
              <div className="slider slider-right">
                  <div className="slide-track">
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/4.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/2.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/3.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/4.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/2.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/4.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/3.png" height="100" alt="" />
                      </div>  
                    </div>
                  </div>
                  {/* Slide Track */}
                </div>
                {/* Slider Ends */}
                <div className="slider">
                  <div className="slide-track">
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/6.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/7.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/6.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/7.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/7.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/7.png" height="100" alt="" />
                      </div>  
                    </div>
                    <div className="slide">
                      <div className="card">
                        <img src="/assets/img/aboutus/team/7.png" height="100" alt="" />
                      </div>  
                    </div>
                 </div>
              </div>
            </div>
        </section>
        {/* Our Team Section Ends... */}
        
        {/* Our Vision Section */}
        <section className='Our-Vision-Section pb-0'>
           <div className='container'>
               <div className='row d-flex justify-content-center' data-aos="zoom-in">
                   <div className='col-lg-10'>
                      <h4 className='section-heading text-center'>Our Vision</h4>
                      <p className='para-p'>At NovelX Technology, we innovate app development, enhancing user experiences with cutting-edge solutions. We drive digital transformation across industries, empowering businesses with creative, high-quality solutions that inspire and transform in dynamic landscapes.</p>
                   </div>
               </div>
               {/* Vision banner */}
               <div className="vision-banner text-center" data-aos="zoom-out">
                    <img src="/assets/img/banner/vision.svg" className='img-fluid img-responsive' alt="Vision Banner" />
               </div>
           </div>
        </section>
     {/* Our Vision Section Ends....*/}


      </section>
      {/* About Us Section Ends */}

     
    </>
  );
}

export default AboutUs;

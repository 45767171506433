import React from 'react';
import Testimonials from '../components/Testimonials';


const Home = () => {
return (
<>
{/* ======= Hero Section ======= */}
<section id="hero" className="hero section-bg">
   <div className="container">
      <div className="row justify-content-center gy-5">
         <div className="col-lg-12 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center text-center text-lg-center">
            <h2 data-aos="fade-left" data-aos-duration="700" className="hero-large-text">
               Pocket-Sized Power: Elevate Your Presence with Award-<span>Winning Apps!</span>
            </h2>
            <p data-aos="fade-up" data-aos-duration="400" data-aos-delay="100" className="px-0 px-lg-5 hero-sm-text">
               Experience the power of pocket-sized convenience with our award-winning mobile apps, expertly crafted to seamlessly elevate your business presence and drive success.
            </p>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="100">
               <a href="/" className="btn btn-get-quote" alt="Get Quote">Get a free Quote</a>
            </div>
         </div>
      </div>
   </div>
   {/* Hero Banner */}
   <div className="row">
      <div className="col-lg-12">
         <div className="container">
            <div data-aos="zoom-out-up" className="hero-banner-container">
               <img src="/assets/img/banner/hero-banner.png" className="img-fluid img-responsive hero-banner-img" alt="Hero Banner" />
            </div>
         </div>
      </div>
   </div>
</section>
{/* End Hero Section */}
<main id="main">
   <div className="container">
      <section className="business-section">
         <div className="row">
            <div data-aos="fade-up" data-aos-duration="600" className="col-lg-6 mb-md-3 mb-sm-3 mb-3 mb-lg-0 mb-xl-0 d-flex align-items-center">
               <div>
                  <h5 className="bus-heading">Develop your business app with India's premier</h5>
                  <p className="bus-description">
                     At Novelx Technology, we pride ourselves on being India's leading app development company, dedicated to transforming your business ideas into successful digital solutions. Our team of skilled developers combines creativity with technical expertise to craft mobile applications that are not only visually stunning but also highly functional. Our commitment to quality, reliability, and customer satisfaction sets us apart. Choose us for your app development needs and experience innovation tailored to meet your unique business requirements.
                  </p>
                  <button type="button" className="btn btn-outline">Read Docs</button>
               </div>
            </div>
            <div className="col-lg-6">
               <div className="row">
                  <div className="col-lg-12" data-aos="fade-left" data-aos-delay="100" data-aos-duration="400">
                     <div className="card BUS-YUGDF-CARD">
                        <div className="card-body">
                           <div className="d-flex">
                              <div className="YUGDF-avatar">
                                   <img src="/assets/img/icons/client-focus.svg" alt="Client Focus" />
                              </div>
                              <div className="ms-3">
                                 <h4 className="YUGDF-CARD-heading">Client Focus</h4>
                                 <p className="YUGDF-CARD-description">
                                    Our developers are proficient in a wide range of technologies, ensuring that we can deliver robust solutions regardless of platform or complexity.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12" data-aos="fade-left" data-aos-delay="200" data-aos-duration="400">
                     <div className="card BUS-YUGDF-CARD">
                        <div className="card-body">
                           <div className="d-flex">
                              <div className="YUGDF-avatar">
                                 <img src="/assets/img/icons/trusted-expertise.svg" alt="Trusted Expertise" />
                              </div>
                              <div className="ms-3">
                                 <h4 className="YUGDF-CARD-heading">Trusted Expertise</h4>
                                 <p className="YUGDF-CARD-description">
                                    We prioritize understanding your business goals and user needs, ensuring that every app we build aligns perfectly with your vision and objectives.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12" data-aos="fade-left" data-aos-delay="300" data-aos-duration="400">
                     <div className="card BUS-YUGDF-CARD">
                        <div className="card-body">
                           <div className="d-flex">
                              <div className="YUGDF-avatar">
                                 <img src="/assets/img/icons/proven-success.svg" alt="Proven Success" />
                              </div>
                              <div className="ms-3">
                                 <h4 className="YUGDF-CARD-heading">Proven Success</h4>
                                 <p className="YUGDF-CARD-description">
                                    With a successful track record across industries, we deliver on-time, within budget, and exceed expectations.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
   {/* App Development Section Starts */}
   <section id="app-developoment-section" className="app-developoment-section">
      <div className="container">
         <div className="row gx-5">
            <div data-aos="fade-up" className="col-lg-12 mb-5">
               <h5 className="section-heading">Transform Your Business with Leading <span>App Development Solutions.</span></h5>
            </div>
            <div data-aos="fade-right" className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
               <div className="card first-child mb-4 mb-sm-4 mb-md-4 mb-lg-0 mb-xl-0">
                  <div className="card-body">
                     <h4 className="app-heading">Android App</h4>
                     <p className="app-des">Attract more customers with Weft's comprehensive Android app development, integration, and support services.</p>
                     <div>
                        <img src="/assets/img/banner/android-app.png" className="img-fluid img-responsive" alt="Android App" />
                     </div>
                  </div>
               </div>
            </div>
            <div data-aos="fade-left" className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
               <div className="card last-child mb-4 mb-sm-4 mb-md-4 mb-lg-0 mb-xl-0">
                  <div className="card-body">
                     <h4 className="app-heading">iOS App</h4>
                     <p className="app-des">Help businesses achieve goals with iOS app development tools and online marketplace solutions for growth and digital enablement.</p>
                     <div>
                        <img src="/assets/img/banner/ios-app.png" className="img-fluid img-responsive" alt="iOS App" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   {/* Achievement Counter Section */}
   <section id="counter-section">
      <div className="achievement-counter-section">
         <div className="row">
            <div className="col-lg-3 col-3" data-aos="fade-down-left" data-aos-delay="400">
               <div className="text-center">
                  <img src="/assets/img/icons/completed-projects.svg" className="img-fluid img-responsive CTR-img89" alt="Completed Projects" />
                  <p className="UBD-name">Completed Projects</p>
                  <h4 className="counter-value">300 +</h4>
               </div>
            </div>
            <div className="col-lg-3 col-3" data-aos="fade-down-left" data-aos-delay="300" >
               <div className="text-center">
                  <img src="/assets/img/icons/customer-satisfaction.svg" className="img-fluid img-responsive CTR-img89" alt="Customer Satisfaction" />
                  <p className="UBD-name">Customer Satisfaction</p>
                  <h4 className="counter-value">70 %</h4>
               </div>
            </div>
            <div className="col-lg-3 col-3" data-aos="fade-down-left" data-aos-delay="200" >
               <div className="text-center">
                  <img src="/assets/img/icons/raised-by-clients.svg" className="img-fluid img-responsive CTR-img89" alt="Raised By Clients" />
                  <p className="UBD-name">Raised by Clients</p>
                  <h4 className="counter-value">$ 70 M</h4>
               </div>
            </div>
            <div className="col-lg-3 col-3" data-aos="fade-down-left" data-aos-delay="100" >
               <div className="text-center">
                  <img src="/assets/img/icons/years-in-business.svg" className="img-fluid img-responsive CTR-img89" alt="Review Rating" />
                  <p className="UBD-name">Years in Business</p>
                  <h4 className="counter-value">6 yrs</h4>
               </div>
            </div>
         </div>
      </div>
   </section>
   {/* Achievement Counter Section Ends */}
   {/* <!-- Portfolio Section --> */}
   <section className="portfolio-section">
      <div className="container">
         <div data-aos="fade-up">
            <h6 className="section-indicator-XP43D">Our Portfolio</h6>
            <h5 className="section-heading">We provide the Perfect Solution to your business growth</h5>
         </div>
         <div className="row gx-5 portfolio-row">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
               <div className="card transparent-card">
                  <img src="/assets/img/portfolio/portfolio-1.png" className="img-fluid img-responsive" alt="Portfolio-1" />
                  <h5 className="portfolio-inner-head">Digital Marketing Agency Website</h5>
                  <p className="portfolio-description">This is a website for a client who want to achieve their goals and meet their users needs while also increasing their reach. Accross all platforms. This is a website rebrand</p>
               </div>
            </div>
            {/* <!-- Col Ends.... --> */}
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
               <div className="card transparent-card">
                  <img src="/assets/img/portfolio/portfolio-2.png" className="img-fluid img-responsive" alt="Portfolio-2" />
                  <h5 className="portfolio-inner-head">Digital Marketing Agency Website</h5>
                  <p className="portfolio-description">This is a website for a client who want to achieve their goals and meet their users needs while also increasing their reach. Accross all platforms. This is a website rebrand</p>
               </div>
            </div>
         </div>
         {/* <!-- Row Ends.. --> */}
         <div className="row gx-5">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
               <div className="card transparent-card">
                  <img src="/assets/img/portfolio/portfolio-3.png" className="img-fluid img-responsive" alt="Portfolio-3" />
                  <h5 className="portfolio-inner-head">Digital Marketing Agency Website</h5>
                  <p className="portfolio-description">This is a website for a client who want to achieve their goals and meet their users needs while also increasing their reach. Accross all platforms. This is a website rebrand</p>
               </div>
            </div>
            {/* <!-- Col Ends... --> */}
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-end">
               <div className="card transparent-card">
                  <img src="/assets/img/portfolio/portfolio-4.png" className="img-fluid img-responsive" alt="Portfolio-4" />
                  <h5 className="portfolio-inner-head">Digital Marketing Agency Website</h5>
                  <p className="portfolio-description">This is a website for a client who want to achieve their goals and meet their users needs while also increasing their reach. Accross all platforms. This is a website rebrand</p>
               </div>
            </div>
            {/* <!-- Col Ends... --> */}
         </div>
      </div>
   </section>
   {/* <!-- Portfolio Section Ends.... --> */}
   
   
   <Testimonials />

   {/* <!-- End Testimonials Section Ends--> */}

   {/* <!-- ===  Our Team Section ==== --> */}
    <section className="our-team-section">
       <div className="container">
         <div data-aos="fade-up">
            <h5 className="section-heading">Get to Know Our Team</h5>
            <p className="section-sub-heading">Bringing together diverse talents and expertise, our team is dedicated to achieving excellence and driving innovation. Together, we work collaboratively to deliver exceptional results and exceed expectations.</p>
          </div>  
          <div data-aos="fade-right" className="row mt-5 gx-4">
            <div className="col-lg-6 col-6">
              <div className="card our-team-card mx-0">
                <div className="our-team-holder">
                    <img src="/assets/img/team/1.png" className="img-fluid our-team-img" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-3">
              <div className="card our-team-card mx-0">
                <div className="our-team-holder">
                    <img src="/assets/img/team/2.png" className="img-fluid our-team-img" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-3">
                <div className="card our-team-card mx-0">
                  <div className="our-team-holder">
                      <img src="/assets/img/team/3.png" className="img-fluid our-team-img" alt="" />
                  </div>
                </div>
            </div>
          </div>
          {/* <!-- First Row Ends... --> */}
          <div data-aos="fade-left" className="row mt-4 gx-4">
            <div className="col-lg-3 col-3">
              <div className="card our-team-card mx-0">
                <div className="our-team-holder">
                    <img src="/assets/img/team/4.png" className="img-fluid our-team-img" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="card our-team-card mx-0">
                <div className="our-team-holder">
                    <img src="/assets/img/team/5.png" className="img-fluid our-team-img" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-3">
                <div className="card our-team-card mx-0">
                  <div className="our-team-holder">
                      <img src="/assets/img/team/6.png" className="img-fluid our-team-img" alt="" />
                  </div>
                </div>
            </div>
          </div>
          {/* <!-- Second Row Ends... --> */}
       </div>
    </section>
  {/* <!-- ===  Our Team Section Ends... ==== --> */}

  {/* <!-- ===  Contact Us Section ==== --> */}
   <section className="contactus-section">
       <div className="container">
           <div className="row">
              <div data-aos="zoom-in" className="col-lg-5 col-md-12 col-sm-12 col-12 contactus-info-col">
                <div className="contactus-info-holder">
                  <h3 className="contactus-heading">Contact Us Today</h3>
                  <p className="pe-0 pe-lg-5">Feel free to reach out today for assistance with any questions or issues. Our dedicated team is here to provide prompt and effective help, ensuring your needs are met.</p>
                </div>
              </div>
              <div data-aos="zoom-out" data-aos-duration="500" className="col-lg-7 form-column">
                  <div className="card form-card">
                     <div className="card-body">
                         <form action="#" method="POST">
                              {/* @csrf */}
                             <div className="row">
                                <div className="col-lg-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Enter your name" />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label htmlFor="pnumber" className="form-label">Phone Number</label>
                                    <input type="tel" className="form-control" id="pnumber" placeholder="Enter your phone no" />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" placeholder="Enter email address" />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label htmlFor="requirements" className="form-label">Requirements</label>
                                    <textarea className="form-control" id="requirements" placeholder="Write here" rows="3"></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                      <button type="button" className="btn btn-get-quote ms-0 mt-3">Get a Quote</button>
                                </div>
                             </div>
                         </form>
                     </div>
                  </div>
              </div>
           </div>
           {/* <!-- Row Ends --> */}
       </div>
       {/* <!-- Container Ends.. --> */}
    
       <div className="slider-container">
          <div className="slider">
              <div className="slide-track">
                  <div className="slide">
                    <img src="/assets/img/brands/1.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/2.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/3.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/4.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/5.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/6.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/7.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/1.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/2.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/3.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/4.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/5.png" height="100" width="250" alt="" />
                  </div>
                  <div className="slide">
                    <img src="/assets/img/brands/6.png" height="100" width="250" alt="" />
                  </div>
              </div>
              {/* <!-- Slide Track --> */}
            </div>
            {/* <!-- Slider --> */}
        </div>
      {/* <!-- Slider Container --> */}
   </section>
 {/* <!-- ===  Contact Us Section  Ends==== --> */}

</main>
</>
);
};
export default Home;
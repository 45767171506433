import React from 'react';


const ContactUs = () => {
  return (
    <>
    {/* Contact Us */}
      <section className="contact-us-section pb-0">
            <div className="CU-bg-overlay">
                <div className="row h-100 d-flex align-items-end">
                     <div className="col-lg-5">
                        <div className='p-5 slogan-container text-center' data-aos="fade-up">
                            <h2 className="get-in-touch">Get in touch.</h2>
                            <h6 className="GIT-slogan">Our team would be love to hear from you!</h6>
                       </div>    
                     </div>
                </div>
            </div>
          <div className='container contact-info-container'>
              <div className='row'>
                <div className='col-lg-4 col-md-4'>
                    <div className='contact-info-wrapper'>
                        <div className='contact-info d-flex '>
                            <img src="/assets/img/icons/location.svg" className="img-fluid img-responsive" alt="Location Icon" />
                            <div className="ms-2">
                                <h5>Address</h5>
                                <p>1st Floor, ABS Plaza, Trivandrum-Nagercoil Hwy, Eraviputhoorkadai, Marthandam, Tamil Nadu 629158</p>
                            </div>
                        </div>
                        <div className='contact-info d-flex'>
                            <img src="/assets/img/icons/mail.svg" className="img-fluid img-responsive" alt="Email Icon" />
                            <div className="ms-2">
                                <h5>Email</h5>
                                <p>novelx@.in</p>
                            </div>
                        </div>
                        <div className='contact-info d-flex'>
                            <img src="/assets/img/icons/phone.svg" className="img-fluid img-responsive" alt="Phone Icon" />
                            <div className="ms-2">
                                <h5>Email</h5>
                                <p>082703 48790</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-8 col-md-6 CUS-form-col' data-aos="fade-left">
                        <div className="card CUS-form-card">
                            <div className='CUS-form-container'>
                                <div className="row">
                                    <div className='col-lg-12'>
                                        <div className="mb-3">
                                            <label htmlFor="fname" className="form-label">NAME</label>
                                            <input type="text" name="fname" className="form-control" id="fname" placeholder="Vitalik Buterin" />
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                    <div className="mb-3">
                                            <label htmlFor="email" className="form-label">EMAIL ADDRESS</label>
                                            <input type="email" name="email" className="form-control" id="email" placeholder="vitalik@company.com" />
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                       <div className="mb-3">
                                            <label htmlFor="company" className="form-label">Company (optional)</label>
                                            <input type="text" name="company" className="form-control" id="company" placeholder="One Solution LLC" />
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                       <div className="mb-3">
                                            <label htmlFor="pnumber" className="form-label">Phone Number</label>
                                            <input type="text" name="pnumber" className="form-control" id="pnumber" placeholder="+91 915 800-1287" />
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                       <div className="mb-3">
                                            <label htmlFor="subject" className="form-label">Subject</label>
                                            <input type="text" name="subject" className="form-control" id="subject" placeholder="I want to ask something" />           
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                       <div className="mb-3">
                                            <label htmlFor="message" className="form-label">Message</label>
                                            <textarea name="message" className="form-control" id="message" rows="4" placeholder="Hi there, I would like to..."></textarea>       
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="d-flex justify-content-end">
                                            <button type="button" className="btn btn-primary send-btn">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                   </div>
               </div>
          </div>
      </section>

      <section className="contactus-bottom-banner pb-0">
          <div className='container'>
              <div className='row'>
                  <div className='col-lg-8 col-md-8' data-aos="fade-right">
                      <h5 className='sm-head'>Subscribe for updates</h5>
                      <h3 className='lg-head'>Cut down on expenses while improving productivity.</h3>
                      <div className='send-mail-wrapper'>
                         <input type="mail" name="email" id="email" placeholder="Enter your email" />
                         <button type='button' className="btn btn-primary subscribe-btn">Subscribe</button>
                      </div>
                      <h5 className="slogan-sm">You can unsubscribe at any time. Read our <a href="/" className="text-primary">privacy policy</a>.</h5>
                  </div>
                  <div className='col-lg-4 col-md-4 position-relative'>
                      <div className='banner-container'>
                         <img src="/assets/img/banner/contactus-bottom.svg" className="img-responsive contactus-bottom-banner-img" alt="Banner" />
                      </div>
                  </div> 
              </div>      
          </div>              
      </section>

     
    </>
  );
}

export default ContactUs;

// utils/loadBootstrap.js
export const loadBootstrapScript = () => {
    return new Promise((resolve, reject) => {
        const existingScript = document.getElementById('bootstrap-script');
        if (existingScript) {
            resolve(); // Bootstrap script is already loaded
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js';
        script.id = 'bootstrap-script';
        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Failed to load Bootstrap script'));

        document.body.appendChild(script);
    });
};

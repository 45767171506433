import React from 'react';


function Footer() {
  return (
    <footer id="contact" className="footer">
      <div className="container menu">
        <div className="row">
          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            {/* Logo on the left */}
            <img
              src={process.env.PUBLIC_URL + '/assets/img/logo.png'}
              alt="Logo"
              className="footer-logo img-responsive"
            />
            <p className="footer-logo-description pe-0 pe-lg-5">
              Novelx Technology enhances customer reach through comprehensive Android app development, integration, implementation, and support services.
            </p>
            <div className="d-flex align-items-center social-links-container">
              <li>
                <a href="/">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/icons/social/facebook.svg'}
                    alt="facebook"
                    className="img-responsive social-icon"
                  />
                </a>
              </li>
              <li className="mx-3">
                <a href="/">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/icons/social/twitter.svg'}
                    alt="twitter"
                    className="img-responsive social-icon"
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/icons/social/linkedin.svg'}
                    alt="linkedin"
                    className="img-responsive social-icon"
                  />
                </a>
              </li>
              <li className="ms-3">
                <a href="/">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/img/icons/social/instagram.svg'}
                    alt="Instagram"
                    className="img-responsive social-icon"
                  />
                </a>
              </li>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 d-block d-sm-block d-md-flex d-lg-flex d-xl-flex justify-content-center my-5 my-sm-5 my-md-0 my-lg-0 my-xl-0">
            <div className="LINK-HGHGG">
              <h5 className="footer-heading">Quick Links</h5>
              {/* Navbar names in the center */}
              <li className="d-flex align-self-start q-link-li">
                <a href="#hero" className="navbar-link">
                  Home
                </a>
              </li>
              <li className="d-flex align-self-start q-link-li">
                <a href="#pricing" className="navbar-link">
                  Pricing
                </a>
              </li>
              <li className="d-flex align-self-start q-link-li">
                <a href="#about" className="navbar-link">
                  About Us
                </a>
              </li>
              <li className="d-flex align-self-start q-link-li">
                <a href="/" className="navbar-link">
                  Help & Support
                </a>
              </li>
              {/* Add more navbar names as needed */}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-block d-sm-block d-md-flex d-lg-flex d-xl-flex justify-content-end">
            <div className="address-holder">
              <h5 className="footer-heading">Address</h5>
              <p className="address-info">
                1st Floor, ABS Plaza, Trivandrum-Nagercoil Hwy, Eraviputhoorkadai, Marthandam, Tamil Nadu 629158
              </p>
            </div>
          </div>
        </div>
        {/* Row Ends */}
        <div className="row mt-5">
          <p className="copyright-content"> <span>{new Date().getFullYear()}</span> &copy; <span className='brand-text'>Novelx <span>Technologies</span></span>. All Rights Reserved</p>
        </div>
      </div>

      <a href="/" className="scroll-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>


     
    </footer>
  );
}

export default Footer;
